/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./AnamneseSettings.vue?vue&type=template&id=3578b1c2&scoped=true&lang=pug&"
import script from "./AnamneseSettings.vue?vue&type=script&lang=ts&"
export * from "./AnamneseSettings.vue?vue&type=script&lang=ts&"
import style0 from "./AnamneseSettings.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./AnamneseSettings.vue?vue&type=style&index=1&id=3578b1c2&lang=scss&scoped=true&"
import style2 from "./AnamneseSettings.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3578b1c2",
  null
  
)

export default component.exports